import { Injectable } from '@angular/core';
import { DefaultService } from '../_defaults/default.service';
import { User, UserAccount } from './user.model';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends DefaultService<User> {
  override url = '/users';

  private profile$ = new BehaviorSubject<UserAccount | null>(null);

  getProFile(option?: { force?: boolean }): Observable<UserAccount> {
    if (this.profile$.getValue() && !option?.force) {
      return of(this.profile$.getValue() as UserAccount);
    } else {
      return this.http.get<UserAccount>(`/profile`).pipe(
        tap((res) => {
          // res.role.name = RoleName.ETAX_OPERATOR;
          this.profile$.next(res);
        })
      );
    }
  }

  createUser(data: Partial<UserAccount>) {
    return this.http.post<UserAccount>(this.url, data);
  }

  updateUserById(id: number, data: Partial<UserAccount>) {
    return this.http.put<UserAccount>(`${this.url}/${id}`, data);
  }

  updateUserDataById(id: number, data: Partial<UserAccount>) {
    return this.http.put<UserAccount>(`${this.url}/${id}`, data);
  }

  changePassword(data: Partial<User>) {
    return this.http.post<User>(`${this.url}/change_password/`, data);
  }

  sendEmail(id: number) {
    return this.http.post<User>(`/user_activates/`, { user_id: id });
  }

  changeDealer(data: { selector_dealer_id: number }) {
    return this.http.post<User>(`${this.url}/change_dealer/`, data);
  }

  clearUser() {
    this.profile$.next(null);
  }

  get profileData(): Observable<UserAccount | null> {
    return this.profile$.asObservable();
  }

  get roleName() {
    return this.profile$.getValue()?.role.name;
  }

  get profileValue() {
    return this.profile$.getValue() as UserAccount;
  }
}
